import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";
import axiosHelper from "./helpers/axiosHelper";
import { setUser } from "./store/BusinessCardStore";

function App() {
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await axiosHelper
      .getUser()
      .then((response) => {
        console.log(response);
        setUser(response.user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Router>
      <Container />
    </Router>
  );
}

export default App;
