import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore from "../../store/BusinessCardStore";
import { getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const redirect = useNavigate();

  return (
    <>
      <Header user={user.username} page={"HOME"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className="d-flex flex-row col-12 align-items-center justify-content-center ">
            <div
              className="  btn-black col-3 rounded cursor-pointer"
              onClick={() => redirect("/Contactlist")}
            >
              Lista Contatti
            </div>
          </div>
          <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-10">
            <div
              className="btn-black rounded cursor-pointer"
              onClick={() => redirect("/Add")}
            >
              Aggiungi Contatti
            </div>
          </div>

          <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-10">
            <div
              className="btn-black rounded cursor-pointer"
              onClick={() => redirect("/PrecontactList")}
            >
              Lista Precontatti
            </div>
          </div>
          <div className="d-flex flex-row col-12 align-items-center justify-content-center mt-10">
            <div
              className="btn-black rounded cursor-pointer"
              onClick={() => redirect("/CompanyList")}
            >
              Company
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
