import { Store } from "pullstate";
import {
  Company,
  CompanyContact,
  ContactData,
  PrecontactData,
  User,
} from "../global";

export interface IBusinessCardStore {
  user: User;
  currentContact: ContactData;
  contacts: ContactData[];
  precontacts: PrecontactData[];
  currentPrecontact: PrecontactData;
  contactPageNumber: number;
  companiesPageNumber: number;
  companies: Company[];
  currentCompany: Company;
  companyContacts: CompanyContact[];
  companyContactsPageNumber: number;
}

const BusinessCardStore = new Store<IBusinessCardStore>({
  contactPageNumber: 0,
  companiesPageNumber: 0,
  user: {
    username: "",
    id: 0,
  },
  currentContact: {
    name: "",
    surname: "",
    email: "",
    phone: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    website: "",
    uuid: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
  },
  contacts: [],
  precontacts: [],
  currentPrecontact: {
    uuid: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    website: "",
    workmail: "",
    role: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: 0,
    company: "",
    mode: "",
    logourl: "",
    imageurl: "",
    link1: "",
    label_link1: "",
    link2: "",
    label_link2: "",
    order_id: "",
    activated: 0,
  },
  companies: [],
  currentCompany: {
    name: "",
    uuid: "",
    address: "",
    email: "",
    logourl: "",
    website: "",
  },
  companyContacts: [],
  companyContactsPageNumber: 0,
});

export default BusinessCardStore;

export const setUser = (user: User) => {
  BusinessCardStore.update((s) => {
    s.user = user;
  });
};

export const setCurrentContact = (contact: ContactData) => {
  BusinessCardStore.update((s) => {
    s.currentContact = contact;
  });
};

export const setCurrentCompany = (company: Company) => {
  BusinessCardStore.update((s) => {
    s.currentCompany = company;
  });
};

export const setContacts = (contacts: ContactData[]) => {
  BusinessCardStore.update((s) => {
    s.contacts = contacts;
  });
};

export const addContacts = (contacts: ContactData[]) => {
  BusinessCardStore.update((s) => {
    s.contacts = [...s.contacts, ...contacts];
  });
};

export const setCompanies = (companies: Company[]) => {
  BusinessCardStore.update((s) => {
    s.companies = companies;
  });
};

export const addCompanies = (companies: Company[]) => {
  BusinessCardStore.update((s) => {
    s.companies = [...s.companies, ...companies];
  });
};

export const addCompanyContacts = (contacts: CompanyContact[]) => {
  BusinessCardStore.update((s) => {
    s.companyContacts = [...s.companyContacts, ...contacts];
  });
};

export const setCurrentPrecontact = (precontact: PrecontactData) => {
  BusinessCardStore.update((s) => {
    s.currentPrecontact = precontact;
  });
};

export const setPrecontacts = (precontacts: PrecontactData[]) => {
  // console.log(precontacts);
  BusinessCardStore.update((s) => {
    s.precontacts = precontacts;
  });
};

export const setContactPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.contactPageNumber = page;
  });
};

export const setCompaniesPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.companiesPageNumber = page;
  });
};

export const setCompanyContactsPageNumber = (page: number) => {
  BusinessCardStore.update((s) => {
    s.companyContactsPageNumber = page;
  });
};

export const updateCompanyLogo = (image: string) => {
  BusinessCardStore.update((s) => {
    s.currentCompany.logourl = image;
  });
};

export const setCompanyContacts = (contacts: CompanyContact[]) => {
  BusinessCardStore.update((s) => {
    s.companyContacts = contacts;
  });
};
