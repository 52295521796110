import React, { useEffect, useState } from "react";

import styles from "./CompanyContacts.module.scss";
import { useStoreState } from "pullstate";
import {
  getCompany,
  getCompanyContacts,
  getCompanyContactsPageNumber,
  getUser,
} from "../../store/Selector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import axiosHelper from "../../helpers/axiosHelper";
import ContactRowBig from "../../components/ContactRowBig/ContactRowBig";
import axios from "axios";
import BusinessCardStore, {
  addCompanyContacts,
  setCompanyContacts,
  setCompanyContactsPageNumber,
} from "../../store/BusinessCardStore";
import { CompanyContact } from "../../global";
import Header from "../../components/Header/Header";

const CompanyContacts: React.FC = () => {
  const companyContacts = useStoreState(BusinessCardStore, getCompanyContacts);
  const company = useStoreState(BusinessCardStore, getCompany);
  const user = useStoreState(BusinessCardStore, getUser);
  const exchangePageCharged = useStoreState(
    BusinessCardStore,
    getCompanyContactsPageNumber
  );

  const [page, setPage] = useState(1);
  const [searchMethod, setSearchMethod] = useState("surname");
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState<CompanyContact[]>([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = Math.min(
      startIndex + itemsPerPage,
      companyContacts.length
    );
    console.log(companyContacts);

    setCurrentItems(companyContacts.slice(startIndex, endIndex));
  }, [page, companyContacts]);

  const getNextPage = async () => {
    if (page > exchangePageCharged) {
      await axiosHelper
        .getCompanyContacts(company.uuid, page)
        .then((response) => {
          if (response.contacts.length > 0) {
            setCompanyContactsPageNumber(page);
            addCompanyContacts(response.contacts);
            setPage((prevPage) => Math.min(prevPage + 1));
          }
        });
    }
    if (companyContacts.length / 10 > page) {
      setPage((prevPage) => Math.min(prevPage + 1));
    }
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const searchContact = async () => {
    if (searchString !== "") {
      if (searchMethod === "surname") {
        await axiosHelper
          .searchContactBySurname(searchString, company.uuid)
          .then((response) => {
            setCompanyContactsPageNumber(1);
            setCompanyContacts(response.contacts);
            setPage(1);
            setSearchString("");
          });
      } else {
        await axiosHelper
          .searchContactByUuid(searchString, company.uuid)
          .then((response) => {
            setCompanyContactsPageNumber(1);
            setCompanyContacts(response.contacts);
            setPage(1);
            setSearchString("");
          });
      }
    } else {
      await axiosHelper.getCompanyContacts(company.uuid, 0).then((response) => {
        setCompanyContactsPageNumber(1);
        setCompanyContacts(response.contacts);
        setPage(1);
      });
    }
  };

  const getList = async () => {
    await axiosHelper
      .getCompanyContacts(company.uuid, 0)
      .then((response) => setCompanyContacts(response.contacts));
  };

  const createNewEmptyContact = async () => {
    await axiosHelper
      .createNewEmptyCompanyContact(company.uuid)
      .then(async (response) => {
        await axiosHelper
          .getCompanyContacts(company.uuid, 0)
          .then((response) => {
            setCompanyContactsPageNumber(1);
            setCompanyContacts(response.contacts);
            setPage(1);
          });
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {/* <Header title="Exchanges List" /> */}
      {/* <div className="d-flex flex-row col-12"> */}
      {/* <PageSkeleton /> */}
      {/* <div className=" col-xl-10 col-12 bg-white p-xl-5 p-3 overflow-hidden"> */}
      <Header user={user.username} page={"LISTA CONTATTI"} />
      <div className="col-12 d-flex flex-column align-items-center justify-content-center">
        <div className="col-10 d-flex flex-row align-items-center justify-content-start mt-5">
          <span className="col-4 col-xl-3 text fw-bold">Search Contact </span>
          <div className="col-8 col-xl-5 d-flex flex-row  align-items-center justify-content-around">
            <span
              className={`col-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${
                styles.btnSearchMethod
              } ${searchMethod === "surname" && styles.btnActive}`}
              onClick={() => setSearchMethod("surname")}
            >
              BY SURNAME
            </span>
            <span
              className={`col-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${
                styles.btnSearchMethod
              } ${searchMethod === "uuid" && styles.btnActive}`}
              onClick={() => setSearchMethod("uuid")}
            >
              BY UUID
            </span>
          </div>
        </div>
        <div className="col-10 d-flex flex-row align-items-center justify-content-start mt-4 ">
          <input
            type="text"
            className="col-5 text"
            placeholder={`Search contact ...`}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <span
            className={`col-2 ms-5  d-flex flex-row align-items-center justify-content-center fw-bold text cursor-pointer ${styles.btnSearch} `}
            onClick={() => searchContact()}
          >
            Search
          </span>
        </div>

        <div className="col-10 d-flex flex-row align-items-center justify-content-center mt-5 mb-5">
          <span
            className={`${styles.newContactBtn} col-3 p-3 sub-title d-flex flex-row align-items-center justify-content-center cursor-pointer`}
            onClick={() => createNewEmptyContact()}
          >
            Create New contact{" "}
          </span>
        </div>
        <div
          className="col-10 d-flex flex-row mt-5 pb-3 "
          style={{ borderBottom: "1px solid black" }}
        >
          <div
            className={`col-xl-3 col-4  text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
          >
            ID
          </div>
          <div
            className={`col-xl-3  col-3 text-xxl d-flex  flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
          >
            Name
          </div>
          <div
            className={`col-xl-2 col-4 text-xxl d-xl-flex d-none flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
          >
            Role
          </div>
          <div
            className={`col-xl-3   text-xxl d-flex flex-row align-items-center justify-content-start fw-bold ${styles.title}`}
          >
            Email
          </div>
        </div>
        <div
          className="d-flex flex-column col-10 align-items-start justify-content-start "
          style={{ minHeight: "55vh" }}
        >
          {currentItems.length > 0 &&
            currentItems.map((item, index) => (
              <ContactRowBig
                key={index}
                name={item.name}
                surname={item.surname}
                email={item.email}
                role={item.role}
                uuid={item.uuid}
              />
            ))}
        </div>

        <div
          className={`d-flex  flex-row align-items-center justify-content-end  col-12 color-black text-xxl ${styles.pager}`}
        >
          <FontAwesomeIcon
            icon={faAngleLeft as IconProp}
            className="pe-3 "
            style={{ fontSize: "1.8rem" }}
            onClick={() => getPrevPage()}
          />
          <span className="fw-bold me-2">Pag {page}</span>
          {/* <span className="color-dark_grey_50 ms-2 fw-bold">{maxPage}</span> */}
          <FontAwesomeIcon
            icon={faAngleRight as IconProp}
            className="ps-3 "
            style={{ fontSize: "1.8rem" }}
            onClick={() => getNextPage()}
          />
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default CompanyContacts;
