import { useNavigate } from "react-router";

import styles from "./CompanyRow.module.scss";
import { Company, ContactData } from "../../global";
import {
  setCurrentCompany,
  setCurrentContact,
} from "../../store/BusinessCardStore";
import { useEffect } from "react";

interface ContactRowProps {
  company: Company;
}

const CompanyRow: React.FC<ContactRowProps> = (props) => {
  const redirect = useNavigate();

  return (
    <>
      <div
        className={`col-12 d-flex flex-row align-items-center justify-content-between p-3 border mt-3 `}
        style={{ borderRadius: "10px", height: "90px" }}
        onClick={() => {
          setCurrentCompany(props.company);
          redirect(`/company/${props.company.uuid}/update`);
        }}
      >
        <div className="col-3 d-flex  flex-row align-items-center justify-content-center h-100 ">
          {props.company.logourl && (
            <img
              src={props.company.logourl}
              className="col-11 "
              style={{
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <div className="col-3 text-s text-start">{props.company.uuid}</div>
        <div className="col-3 text-s text-start">{props.company.name}</div>
        <div className="col-3 text-s text-start">{props.company.address}</div>
      </div>
    </>
  );
};

export default CompanyRow;
