import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import styles from "./AddContact.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setCurrentContact,
} from "../../store/BusinessCardStore";
import { getCurrentContact, getUser } from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useForm } from "react-hook-form";
import { ContactData, uploadImageData } from "../../global";
import axiosHelper from "../../helpers/axiosHelper";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";

const AddContact: React.FC = () => {
  const redirect = useNavigate();
  const user = useStoreState(BusinessCardStore, getUser);
  const currentContact = useStoreState(BusinessCardStore, getCurrentContact);
  const [error, setError] = useState("");
  const [whatsapp, setWhatsapp] = useState(false);
  const [image, setImage] = useState<File>();
  const [imagebtn, setImagebtn] = useState(false);
  const [logo, setLogo] = useState<File>();
  const [logobtn, setLogobtn] = useState(true);
  const [visibleList, setVisibleList] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactData>();

  const onSubmit = async (data: ContactData) => {
    if (whatsapp) {
      data.whatsapp = 1;
    } else {
      data.whatsapp = 0;
    }
    console.log(data);
    await axiosHelper
      .newContact(data)
      .then((response) => {
        console.log(response);
        setVisibleList(true);
        setCurrentContact(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goToList = async () => {
    await axiosHelper
      .getContactForPages(0)
      .then((response) => redirect("/List"))
      .catch((error) => console.log(error));
  };

  const onDropImage = (e: any) => {
    console.log(e?.[0]);
    setImage(e?.[0]);
  };

  const onDropLogo = (e: any) => {
    console.log(e?.[0]);
    setLogo(e?.[0]);
  };

  const uploadImage = async () => {
    if (image) {
      const data: uploadImageData = {
        uuid: currentContact.uuid,
        image: image,
      };
      await axiosHelper
        .addContactImage(data)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    }
  };

  const uploadLogo = async () => {
    if (logo) {
      const data: uploadImageData = {
        uuid: currentContact.uuid,
        image: logo,
      };
      await axiosHelper
        .addContactLogo(data)
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    }
  };
  return (
    <>
      <Header user={user.username} page={"ADD CONTACT"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" col-12 rounded color-black "
          >
            <div className=" d-flex flex-row  col-12 align-items-center justify-content-center mt-7 ">
              <span className="bold-title"> NEW CONTACT</span>
            </div>
            <div className=" d-flex flex-column  pb-5 align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                name :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("name")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                surname :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("surname")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                email :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("email")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                phone :{" "}
                <span className="ms-3 fw-normal">
                  (tutto attaccato con prefisso +..)
                </span>
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("phone")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                whatsapp :{" "}
                <Switch
                  className="ms-5"
                  checked={whatsapp}
                  onChange={() => setWhatsapp(!whatsapp)}
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-4  me-5">
                workmail :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("workmail")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                role :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("role")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                website :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("website")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                linkedin :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("linkedin")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                facebook :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("facebook")}
                />
              </div>
            </div>
            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                instagram :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("instagram")}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  me-5">
                company :
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("company")}
                />
              </div>
            </div>

            <div className=" d-flex flex-column pb-5  align-items-center justify-content-center mt-7 ">
              <div className="sub-title d-flex flex-row align-items-center justify-content-center col-8  mb-3">
                mode : ( NIGHT, LIGHT )
              </div>
              <div className="col-7 ">
                <input
                  type="text"
                  className="text-center w-100 sub-title"
                  style={{
                    borderRadius: "10px",
                    height: "40px",
                    border: "0px",
                  }}
                  {...register("mode")}
                />
              </div>
            </div>

            <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 mb-7  ">
              <button className="btn-success col-6 rounded">
                CREATE CONTACT
              </button>
              {visibleList && (
                <button
                  className="btn-success col-6 rounded ms-5"
                  onClick={() => goToList()}
                >
                  GO TO LIST
                </button>
              )}
            </div>
            {error !== "" && (
              <div className=" d-flex flex-row   align-items-center justify-content-center mt-7 ">
                <span className=" text-16">{error}</span>
              </div>
            )}
          </form>
          <div className=" col-12 d-flex flex-row align-items-center justify-content-center">
            <span className="sub-title ">Immagine:</span>
          </div>
          {!image && (
            <>
              <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-5 pb-5">
                <div
                  className=" mt-3 bg-light col-7 d-flex flex-row align-items-center justify-content-center"
                  style={{
                    border: "1px dashed black",
                    borderRadius: "20px",
                  }}
                >
                  <Dropzone
                    onDrop={onDropImage}
                    accept={{
                      "image/jpeg": [],
                      "image/png": [],
                      "image/jpg": [],
                      // "application/pdf": [],
                    }}
                    maxSize={31457280}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className={` bg-light d-flex align-items-center  justify-content-center`}
                      >
                        <div
                          className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <span>
                            Drag and drop an image or{" "}
                            <span className="color-blue">choose a file</span>{" "}
                            from your computer <br />
                            (allowed formats: .jpg, .png)
                          </span>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            </>
          )}
          <div
            className={`col-12 mb-7 ${
              image
                ? "d-flex flex-row align-items-center justify-content-center"
                : "d-none"
            }`}
          >
            <div
              className={`col-9   d-flex flex-column align-items-center justify-content-center `}
            >
              {image && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={URL.createObjectURL(image)}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl">{image.name}</span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setImage(undefined)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {image && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12 mb-5">
              <div
                className="btn-success rounded  "
                onClick={() => uploadImage()}
              >
                {" "}
                Add Image{" "}
              </div>
            </div>
          )}
          <div className="col-12 text-center sub-title ">logo: </div>
          {!logo && (
            <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-5">
              <div
                className=" mt-3 bg-light col-7 d-flex flex-row align-items-center justify-content-center"
                style={{
                  border: "1px dashed black",
                  borderRadius: "20px",
                }}
              >
                <Dropzone
                  onDrop={onDropLogo}
                  accept={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    // "application/pdf": [],
                  }}
                  maxSize={31457280}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className={` bg-light d-flex align-items-center  justify-content-center`}
                    >
                      <div
                        className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <span>
                          Drag and drop an image or{" "}
                          <span className="color-blue">choose a file</span> from
                          your computer <br />
                          (allowed formats: .jpg, .png)
                        </span>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
          <div
            className={`col-12 mb-7 ${
              logo
                ? "d-flex flex-row align-items-center justify-content-center"
                : "d-none"
            }`}
          >
            <div
              className={`col-9   d-flex flex-column align-items-center justify-content-center `}
            >
              {logo && (
                <>
                  <div className=" mt-4 d-flex" style={{ width: "100%" }}>
                    <div className={`${styles.previewImg}`}>
                      <img
                        src={URL.createObjectURL(logo)}
                        className={`${styles.previewImg}`}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                      <span className="text-xl">{logo.name}</span>
                    </div>
                    <div className=" d-flex align-items-center justify-content-end ">
                      <div
                        className={` ${styles.removeButton}`}
                        onClick={() => setLogo(undefined)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark as IconProp}
                          className=" text-16 me-0"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {logo && (
            <div className="d-flex flex-row align-items-center justify-content-center col-12 mb-5">
              <div
                className="btn-success rounded  "
                onClick={() => uploadLogo()}
              >
                {" "}
                Add Logo{" "}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AddContact;
