import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./PermanentlyDeletePopUp.module.scss";

interface PopUpProps {
  popup: boolean;
  name?: string;
  surname?: string;
  success: () => void;
  close: () => void;
  buttonText: string;
}

const PermanentlyDeletePopUp: React.FC<PopUpProps> = (props) => {
  const [popup, setPopup] = useState(props.popup);
  const [phrase, setPhrase] = useState("");

  const checkPhrase = () => {
    if (phrase === "elimina definitivamente") {
      console.log("frase corretta");
      props.success();
    }
  };

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => setPopup(!popup)}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div
          className={`d-flex flex-column justify-content-center text-center  full bg-light-gray ${styles.rounded}`}
        >
          <Modal.Body className="d-flex flex-column  justify-content-center p-3">
            <div className="col-12  ">
              Sei sicuro di cancellare il contatto di {props.name}
              {props.surname}
            </div>
            <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-center ">
              <span>
                Scrivi <i>elimina definitivamente</i> per poter eliminare il
                contatto.
              </span>
            </div>
            <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-center ">
              <input
                type="text "
                style={{ borderRadius: "10px" }}
                onChange={(e) => setPhrase(e.target.value)}
              />
            </div>
            <div className="col-12 d-flex flex-row mt-4 align-items-center justify-content-around ">
              <div
                className="btn-danger rounded col-3 cursor-pointer"
                onClick={() => {
                  props.close();
                  setPopup(false);
                }}
              >
                Back
              </div>
              <div
                className="btn-success rounded col-4 cursor-pointer"
                onClick={() => checkPhrase()}
              >
                {props.buttonText}
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PermanentlyDeletePopUp;
