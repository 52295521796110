import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home/Home";
import AddContact from "./routes/AddContact/AddContact";
import ContactList from "./routes/ContactList/ContactList";
import UpdateContact from "./routes/UpdateContact/UpdateContact";
import Registration from "./routes/Registration/Registration";
import PrecontactList from "./routes/PrecontactList/PrecontactList";
import UpdatePrecontact from "./routes/UpdatePrecontact/UpdatePrecontact";
import CompanyList from "./routes/CompanyList/CompanyList";
import UpdateCompany from "./routes/UpdateCompany/UpdateCompany";
import CreateCompany from "./routes/CreateCompany/CreateCompany";
import CompanyContacts from "./routes/CompanyContacts/CompanyContacts";

const Container = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Home />} />
      <Route path={`/Add`} element={<AddContact />} />
      <Route path={`/Contactlist`} element={<ContactList />} />
      <Route path={`/Precontactlist`} element={<PrecontactList />} />
      <Route path={`/:uuid/update`} element={<UpdateContact />} />
      <Route path={`/precontact/:uuid/update`} element={<UpdatePrecontact />} />
      <Route path={`/registra`} element={<Registration />} />
      <Route path={`/CompanyList`} element={<CompanyList />} />
      <Route path={`/company/:uuid/update`} element={<UpdateCompany />} />
      <Route path={`/company/create`} element={<CreateCompany />} />
      <Route
        path={`/company/contact-list/:uuid`}
        element={<CompanyContacts />}
      />
    </Routes>
  );
};
export default Container;
