import React, { useEffect, useState } from "react";
import styles from "./CompanyList.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addCompanies,
  addContacts,
  setCompanies,
  setCompaniesPageNumber,
  setContactPageNumber,
  setContacts,
} from "../../store/BusinessCardStore";
import {
  getCompanies,
  getContactPageNumber,
  getContacts,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { Company, ContactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import CompanyRow from "../../components/CompanyRow/CompanyRow";

const CompanyList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);

  const companies = useStoreState(BusinessCardStore, getCompanies);

  const contactPageCharged = useStoreState(
    BusinessCardStore,
    getContactPageNumber
  );

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  useEffect(() => {
    getCompanyForCurrentPage();
  }, [page]);

  const getCompanyForCurrentPage = async () => {
    await axiosHelper.getCompanies(page).then((response) => {
      console.log(response);
      setCompanies(response.companies);
    });
  };

  const getNextPage = async () => {
    if (page > contactPageCharged) {
      await axiosHelper.getCompanies(page).then((response) => {
        if (response.lenght > 0) setCompaniesPageNumber(page);
        addCompanies(response);
        setPage((prevPage) => Math.min(prevPage + 1));
      });
    }
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToCreateCompany = () => {
    redirect("/company/create");
  };

  return (
    <>
      <Header user={user.username} page={"LISTA COMPANIES"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            <span
              className={`${styles.createBtn} d-flex flex-row align-items-center justify-content-center p-3 fw-bold cursor-pointer`}
              onClick={() => goToCreateCompany()}
            >
              CREA NUOVA COMPANY
            </span>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            {companies.map((item: Company) => (
              <CompanyRow company={item} />
            ))}
          </div>
          <div
            className={`d-flex flex-row align-items-center justify-content-end  col-12 color-black text-xxl mt-10 ${styles.pager}`}
          >
            <FontAwesomeIcon
              icon={faAngleLeft as IconProp}
              className="pe-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getPrevPage()}
            />
            <FontAwesomeIcon
              icon={faAngleRight as IconProp}
              className="ps-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getNextPage()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyList;
