import React, { useEffect, useState } from "react";
import styles from "./ContactList.module.scss";
import Login from "../../components/Login/Login";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  addContacts,
  setContactPageNumber,
  setContacts,
} from "../../store/BusinessCardStore";
import {
  getContactPageNumber,
  getContacts,
  getUser,
} from "../../store/Selector";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import axiosHelper from "../../helpers/axiosHelper";
import { ContactData } from "../../global";
import ContactRow from "../../components/ContactRow/ContactRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ContactList: React.FC = () => {
  const user = useStoreState(BusinessCardStore, getUser);
  const contacts = useStoreState(BusinessCardStore, getContacts);

  const contactPageCharged = useStoreState(
    BusinessCardStore,
    getContactPageNumber
  );

  const redirect = useNavigate();

  const [page, setPage] = useState(0);

  //   useEffect(() => {
  //     getContactForCurrentPage();
  //   }, []);

  useEffect(() => {
    getContactForCurrentPage();
  }, [page]);

  const getContactForCurrentPage = async () => {
    await axiosHelper
      .getContactForPages(page)
      .then((response: ContactData[]) => {
        console.log(response);
        setContacts(response);
      });
  };

  const createNewEmpty = async () => {
    await axiosHelper.createNewEmptyContact().then((response) => {
      console.log(response);
      getContactForCurrentPage();
    });
  };

  const getNextPage = async () => {
    if (page > contactPageCharged) {
      await axiosHelper.getContactForPages(page).then((response) => {
        setContactPageNumber(page);
        addContacts(response);
      });
    }
    setPage((prevPage) => Math.min(prevPage + 1));
  };

  const getPrevPage = () => {
    if (page > 1) setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Header user={user.username} page={"LISTA CONTATTI"} />
      {!user.username && <Login />}
      {user.username && (
        <div className="p-5 d-flex flex-column align-items-center justify-content-start mt-10">
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            <div
              className={`d-flex flex-row col-8 align-items-center justify-content-center sub-title ${styles.emptyBtn}`}
              onClick={() => createNewEmpty()}
            >
              create new empty contact
            </div>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center col-12">
            {contacts.map((item: ContactData) => (
              <ContactRow contact={item} />
            ))}
          </div>
          <div
            className={`d-flex flex-row align-items-center justify-content-end  col-12 color-black text-xxl mt-10 ${styles.pager}`}
          >
            <FontAwesomeIcon
              icon={faAngleLeft as IconProp}
              className="pe-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getPrevPage()}
            />
            <FontAwesomeIcon
              icon={faAngleRight as IconProp}
              className="ps-3 "
              style={{ fontSize: "1.8rem" }}
              onClick={() => getNextPage()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactList;
