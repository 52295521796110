import axios from "axios";
import Cookies from "js-cookie"; // Se utilizzi i cookie per salvare il token
import { jwtDecode } from "jwt-decode";
import axiosHelper from "./axiosHelper";

// Crea un'istanza di Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT as string,
  withCredentials: true, // Include i cookie nelle richieste se necessario
});

// Aggiungi un interceptor per aggiungere l'header Authorization
axiosInstance.interceptors.request.use(
  async (config) => {
    // const token = Cookies.get("accessToken"); // Recupera il token dai cookie
    // Oppure se usi localStorage

    // console.log("ci sono arrivato");

    // if (decodedToken.exp)
    //   console.log(decodedToken.exp * 1000 < currentDate.getTime());
    const currentDate = new Date();
    const token = localStorage.getItem("business_token");
    const decodedToken = jwtDecode(token as string);

    if (decodedToken.exp && decodedToken.exp * 1000 < currentDate.getTime()) {
      // console.log("il token è scaduto");
      // await axiosHelper.refreshTokens();
    } else {
      // console.log("token valido");
    }

    config.headers["authorization"] =
      "Bearer " + localStorage.getItem("business_token");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
